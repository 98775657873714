define("ucrm-client-signup-form/components/user-details", ["exports", "ucrm-client-signup-form/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ["container-fluid"],

    processing: false,
    selectedState: null,
    // states: null,
    // defaultCountry : null, // Set defaultCountry to the default value

    init: function init() {
      this._super.apply(this, arguments);
      this.model.client.countryId = 249;
      this.model.client.stateId = null;
      // this.get('ajax').post(ENV.APP.host, {
      //   data: {
      //     frontendKey: ENV.APP.frontendKey,
      //     api: {
      //       type: 'GET',
      //       endpoint: 'countries/states',
      //       data: {countryId: this.get('model.client.countryId')}
      //     }
      //   }
      // }).then((response) => {
      //   console.log('response', response);
      //   this.set('states', response);
      // });
      // const test = [{ id: 265, name: 'Zimbabwe', code: 'ZW' },
      //   { id: 31, name: '\u00c5land Islands', code: 'AX' }];
      // this.set('states', test);
    },

    states: Ember.computed("model.client.countryId", function () {
      if (this.get("model.client.countryId") == 249 || this.get("model.client.countryId") == 54) {
        return this.get("ajax").post(_environment.default.APP.host, {
          data: {
            frontendKey: _environment.default.APP.frontendKey,
            api: {
              type: "GET",
              endpoint: "countries/states",
              data: { countryId: this.get("model.client.countryId") }
            }
          }
        });
      } else {
        // this.set('model.client.stateId', null);
        return false;
      }
    }),
    defaultCountry: Ember.computed("model.countries", function () {
      // Retrieve the default country object based on its name
      return this.model.countries.find(function (country) {
        return country.name === "United States";
      });
    }),
    // Set the default state after the states are loaded
    // setDefaultState: computed('states', function() {
    //   if (this.states) {
    //     // Find the default state object based on its name
    //     const defaultState = this.states.find(state => state.name === 'United States');
    //     // Set the default state
    //     this.set('selectedState', defaultState);
    //   }
    // }),

    actions: {
      selectCountry: function selectCountry(country) {
        this.set("model.client.countryId", country.id);
        this.set("selectedCountry", country);
        this.set("defaultCountry", country);

        this.set("model.client.stateId", null);
        this.set("selectedState", null);
      },
      selectState: function selectState(state) {
        this.set("model.client.stateId", state.id);
        this.set("selectedState", state);

        if (state.id == 249 || state.id == 54) {
          return this.get("ajax").post(_environment.default.APP.host, {
            data: {
              frontendKey: _environment.default.APP.frontendKey,
              api: {
                type: "GET",
                endpoint: "countries/states",
                data: { countryId: state.id }
              }
            }
          });
        } else {
          // this.set('model.client.stateId', null);
          return false;
        }
      },
      submit: function submit(client) {
        var _this = this;

        client.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set("pending", true);
          _this.set("processing", true);
          _this.set("failure", false);

          var $isLead = _environment.default.APP.isLead === "no" ? false : true;

          if (validations.get("isValid")) {
            _this.get("ajax").post(_environment.default.APP.host, {
              headers: {
                "Content-Type": "application/json"
              },
              data: {
                frontendKey: _environment.default.APP.frontendKey,
                api: {
                  type: "POST",
                  endpoint: "clients",
                  data: {
                    clientType: 1,
                    isLead: $isLead,
                    firstName: _this.get("model.client.firstName"),
                    lastName: _this.get("model.client.lastName"),
                    street1: _this.get("model.client.street1"),
                    street2: _this.get("model.client.street2"),
                    city: _this.get("model.client.city"),
                    countryId: _this.get("model.client.countryId"),
                    stateId: _this.get("model.client.stateId"),
                    zipCode: _this.get("model.client.zipCode"),
                    username: _this.get("model.client.email"),
                    contacts: [{
                      email: _this.get("model.client.email"),
                      phone: _this.get("model.client.phone"),
                      name: _this.get("model.client.firstName") + " " + _this.get("model.client.lastName")
                    }]
                    // "attributes": [
                    //   {
                    //     value: String(this.get('model.client.agreedToTAC')),
                    //     customAttributeId: 2,
                    //   }
                    // ]
                  }
                }
              }
            }).catch(function (resp) {
              if (resp.payload !== undefined && resp.payload !== null) {
                if (resp.payload.redirect === true) {
                  _this.set("failure", false);
                  // this.transitionToRoute('signup.account', { queryParams: { expired: true }});
                  _this.get("changeRoute")("signup.account");
                } else {
                  _this.set("errors", resp.payload.errors);
                }
              }
              _this.set("pending", false);
              _this.set("failure", true);
            }).then(function () {
              if (_this.get("failure") !== true) {
                _this.get("changeRoute")("signup.complete");
              }
              _this.set("pending", false);
            });

            _this.set("processing", false);
          }
        });
      }
    }
  });
});